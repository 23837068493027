table.abm-table tbody td{
  padding-left: 8px; 
  padding-right: 8px;
}
table.abm-table tbody tr:nth-child(even) {
  background-color: #00000015;
}

table.abm-table tbody tr:nth-child(odd) {
  background-color: #00000030;
}

table.abm-table tbody tr.selected:nth-child(even) {
  background-color: #00000075;
}

table.abm-table tbody tr.selected:nth-child(odd) {
  background-color: #00000075;
}

table.abm-table tbody tr.closed:nth-child(even) {
  background-color: #d43030;
  color:rgb(255, 232, 232);
}

table.abm-table tbody tr.closed:nth-child(odd) {
  background-color: #d54242;
  color:rgb(255, 232, 232);
}

table.abm-table tbody tr.selected.closed:nth-child(even) {
  background-color: #521313;
  color:rgb(255, 232, 232);
}

table.abm-table tbody tr.selected.closed:nth-child(odd) {
  background-color: #521c1c;
  color:rgb(255, 232, 232);
}